@use './typography.scss' as typo;

// Grid breakpoints

// change this breakpoint, container max, gutter, and offset
// as needed for responsivity and grid
$grid-breakpoints: (
  xs: 375px,
  sm: 730px,
  md: 965px,
  lg: 1200px,
  surface-pro: 1328px,
  xl: 1380px,
) !default;

// Max Grid containers
$container-max-widths: (
  xs: 600px,
  sm: 645px,
  md: 880px,
  lg: 1115px,
  xl: 1295px,
) !default;

// Gutter Width
$gutter-width: (
  xs: 15px,
  sm: 15px,
  md: 20px,
  lg: 25px,
  xl: 25px,
) !default;

$offset-width: (
  xs: 0,
  sm: 0,
  md: 30px,
  lg: 42px,
  xl: 60px,
) !default;

// Grid columns
$grid-columns: 12 !default;

// This always pulls md size as default
$grid-gutter-width: map-get(
  $map: $gutter-width,
  $key: 'md',
);

$grid-row-columns: 6 !default;
// enabling this will give capability to use css class directly without providing
// mixins on the classnames.
$enable-grid-classes: false !default;

// Brand Colors

// Typography

$font-regular: OpenSans Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', sans-serif;
$font-medium: $font-regular;
$font-bold: $font-regular;
$font-sf-pro-text-regular: 'SF Pro Text Regular', Helvetica, sans-serif;
$font-sf-pro-text-semibold: 'SF Pro Text Semibold', Helvetica, sans-serif;
$font-sf-pro-text-bold: 'SF Pro Text Bold', Helvetica, sans-serif;
$font-openSans-regular: OpenSans Regular;
$font-openSans-medium: OpenSans SemiBold;
$font-openSans-bold: OpenSans Bold;
$font-Segoe-regular: Segoe UI Regular;
$font-Segoe-light: Segoe UI Light;
$font-Segoe-bold: Segoe UI Bold;
$font-Calibri: 'Calibri';
$font-Calibri-bold: 'Calibri Bold';
// html font size
$font-size-html: 10px;
$line-height-base: 1.35;

$font-size-base: 14px;

// Brand Colors
$brand-white: #ffffff;
$brand-black: #000000;
$brand-grey: #a5a6ac;
$brand-dark-grey: #2f2f2f;
$brand-black-text: #222222;
$brand-orange: #f66620;
$navbar-dark: #222222;
$navbar-username: #ffffff;
$navbar-date: #d3d3d3;
$light-grey: #7a7a7a;
$dark-grey: #494949;
$swiper-grey: #c4c4c4;
$brand-grey-30: #7a7a7a;
$brand-button-hover: #ab3d07;
$brand-box: #354050;
$brand-border: #e2e2e2;
$brand-button: #1d6691;
$brand-background: #17587e;
$brand-image-vendor: #a3a3a3;
$brand-button-vendor: #d6d6d6;
$brand-button-regeneron: #2c83b5;
$brand-button-text: #5a5a5a;
$brand-light-green: #21c064;
$brand-title-border: #a7a7a7;
$brand-dropdown: #164867;
$brand-dropdown-text: #80cfff;
$brand-dropdown-background: #306a8d;
$brand-file-border: #fdfdfd;
$brand-button-background: #16b257;
$brand-folder: #af1308;
$footer-border: #174765;
$footer-text: #71a3c1;
$brand-icon: #215678;
$tab-background: #164867;
$tab-header: #164e70;
$tab-left-header: #eaf4eb;
$tab-right-header: #354050;
$brand-leftbar: #174e71;
$brand-leftbar-text: #5e869f;
$brand-leftbar-triangle: #80cfff;
$brand-leftbar-item: #bce6ff;
$brand-file-text: #08aefa;
$brand-directory: #848486;
$brand-folder-triangle: #e8e8e8;
$brand-footer-mobile: #00e9ff;
$brand-created: #b2b2b2;
$brand-employee: #23004c;
