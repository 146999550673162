@use "../../../style/variables" as *;
@use '../../../style/mixins' as *;
.login {
  align-items: center;
  background: url("../../../assets/images/background.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo {
      margin-top: 160px;
      margin-bottom: 60px;
    }

    .button {
      :not(:first-child):not(p) {
        margin-top: 28px;
      }
      p {
        align-items: center;
        display: flex;
        justify-content: center;
        a {
          color: $footer-text;
        }
      }
    }
    .contact {
      color: $footer-text;
      height: 48px;
      margin: 0;
      padding: 30px 10px 0 10px;
      text-align: center;
      width: 418px;
    }
  }
  .info {
    margin: 0;
    color: $footer-text;
    font-size: 14px;
    font-weight: 400;
    max-height: 350px;
    padding-top: 55px;
    width: 80%;
    font-family: $font-Calibri;
    line-height: 22px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    width: 100%;
    .footer-content {
      align-self: center;
      border-bottom: 1px solid $footer-border;
      display: flex;
      justify-content: space-between;
      width: 80%;
      img {
        height: 31px;
      }
      p {
        color: $footer-text;
        font-size: 14px;
        margin: 0;
      }
    }
    .copyright {
      align-self: center;
      color: $footer-text;
      display: flex;
      font-size: 14px;
      justify-content: flex-start;
      margin: 0;
      width: 80%;
    }
  }
}
