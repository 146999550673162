@use '../../../style/variables' as *;
@use '../../../style/mixins' as *;

.wrapper {
  height: 57px;
  width: 400px;

  .button {
    background: $brand-button;
    border: none;
    color: $brand-white;
    cursor: pointer;
    height: 100%;
    position: relative;
    width: 100%;
    .image {
      align-items: center;
      background: $brand-background;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 59px;
      img {
        height: 27px;
        width: 27px;
      }
      &.image-medical {
        background-color: $brand-image-vendor;
      }
      &.image-employee {
        background-color: $brand-employee;
      }
    }
    &.button-medical {
      background: $brand-button-vendor;
      color: $brand-button-text;
    }
    &.button-regeneron {
      background: $brand-button-regeneron;
    }
  }
  &.wrapper-sign-in {
    width: 401px;
  }

  &.wrapper-release {
    height: 37px;
    width: 166px;
  }
}
